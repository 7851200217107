import React from "react";



function Last() {
  return (
    <main className="max-w-screen-2xl w-full mx-auto p-10 lg:p-20">     


          <div className="flex justify-between items-center flex-wrap gap-y-5 ">

            <div data-aos="fade-left" className=" font-inter-serif ">
              <h1 className=" font-bold text-[24px]">Consulting Agency For Your Business</h1>
              <p className="font-light text-[14px]">the quick fox jumps over the lazy dog</p>
            </div>

            <div data-aos="fade-right" >
              <div className="font-inter-serif ">
                <h1 className="bg-[#25537E] inline-block rounded-full py-3 px-8 cursor-pointer text-white text-[14px]  " >Let's Talk</h1>
              </div>



            </div>

          </div>
       
    
    </main>
  );
}

export default Last;
