import React, { useEffect } from "react";
import uni from '../assets/banner/Untitled-1.png'

function AboutExp() {


  return (
    <main className="bg-[#F2F4F5] mt-20   ">

    <div className="max-w-screen-2xl w-full mx-auto px-4 2xl:px-80 lg:px-56 py-24 ">
    
      <div className="text-center  font-inter-serif">
        <h1 data-aos="fade-up" className="text-[36px] font-bold">
        Sentient AI Enhancing Your Experience 
        </h1>
        <p className="leading-[29px] font-light mt-5 ">Our comprehensive approach includes strategic advisor, talent development, and cutting-edge AI solutions to drive sustainable growth, enhance customer experience, improve operational efficiency, and managed risk effectively.
        SentientAI is an innovative AI products and Services company dedicated to transforming organization into Sentient Enterprises. We Empower businesses to become AI-first by leveraging advanced AI and data strategies.</p>
      </div>
  
    </div>
  
  
  
  </main>
  
  );
}

export default AboutExp;
