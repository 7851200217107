import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/contact.png";
import mailIcon from "../assets/vector/mail.png";
import img1 from "../assets/vector/mail.png";
import phone from "../assets/vector/phne.png";
import map from "../assets/vector/MapPinArea.png"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { FaRegSquarePlus } from "react-icons/fa6";
import contact from '../assets/media/contact.png'

function Contact() {

  const data = [
    {
      img: mailIcon,
      title: "Our email",
      para: "hello@example.com"
    },
    {
      img: phone,
      title: "Call us",
      para: "998798786"
    },
    {
      img: map,
      title: "Find us",
      para: "Open Google Maps"
    },
  ];


  return (
    <main className=" ">
      <Navbar />

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-14 "
        style={{
          backgroundImage: `linear-gradient(to right, #133D64CC, #6ED8D9CC), url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="max-w-screen-2xl mx-auto w-full font-semibold mb-6 text-[36px] lg:text-[48px] text-white">
          <p className="pt-24 font-inria-sans font-semibold">Contact Us</p>
        </div>
      </div>

      <div className="bg-[#F4F4F47D]">

        <div className="lg  :p-16 p-4 max-w-screen-2xl mx-auto w-full ">
          <form data-aos="fade-up" className="my-8 flex lg:flex-nowrap flex-wrap gap-5 items-center ">
            <div className="w-full lg:w-1/3   ">
              <div data-aos="fade-down">
                <img src={contact} alt="" />

              </div>
            </div>

            <div className=" w-full lg:w-2/3">
              <h1 className="text-[40px] font-semibold lg:text-[64px] font-inter-serif leading-[63px] font-semibold bg-gradient-to-r from-[#25537E] to-[#3DB7B7] bg-clip-text text-transparent ">
                Let’s talk
              </h1>
              <div className="border border-[#E0DBDB] px-8 py-12 rounded-lg mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 lg:grid-cols-2">
                {/* First Name */}
                <div className="sm:col-span-1">
                  <label htmlFor="first-name" className="block font-medium text-[#0C0C0C]">First name</label>
                  <div className="mt-2">
                    <input
                      required
                      type="text"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full py-1.5 text-gray-900 shadow-sm border-b border-[#DFDFDF] placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                    />
                  </div>
                </div>

                {/* Last Name */}
                <div className="sm:col-span-1">
                  <label htmlFor="last-name" className="block font-medium text-[#0C0C0C]">Last name</label>
                  <div className="mt-2">
                    <input
                      required
                      type="text"
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full py-1.5 text-gray-900 shadow-sm border-b border-[#DFDFDF] placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                    />
                  </div>
                </div>

                {/* Email */}
                <div className="sm:col-span-1">
                  <label htmlFor="email" className="block font-medium text-[#0C0C0C]">Email</label>
                  <div className="mt-2">
                    <input
                      required
                      type="email"
                      id="email"
                      autoComplete="email"
                      className="block w-full py-1.5 text-gray-900 shadow-sm border-b border-[#DFDFDF] placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                    />
                  </div>
                </div>

                {/* Phone */}
                <div className="sm:col-span-1">
                  <label htmlFor="phone" className="block font-medium text-[#0C0C0C]">Phone</label>
                  <div className="mt-2">
                    <input
                      required
                      type="tel"
                      id="phone"
                      autoComplete="tel"
                      className="block w-full py-1.5 text-gray-900 shadow-sm border-b border-[#DFDFDF] placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                    />
                  </div>
                </div>

                {/* Message */}
                <div className="col-span-full">
                  <label htmlFor="message" className="block font-medium text-[#0C0C0C]">Message</label>
                  <div className="mt-2">
                    <textarea
                      required
                      id="message"
                      autoComplete="off"
                      rows="4"
                      className="block w-full py-1.5 text-gray-900 shadow-sm border-b border-[#DFDFDF] placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                    />
                  </div>
                </div>

                <div className="text-center sm:col-span-1 py-2 mt-8 bg-[#25537E] text-white rounded-full cursor-pointer">
                  <h1 className="text-[18px]">Submit Now</h1>
                </div>
              </div>



            </div>
          </form>

        </div>

      </div>

      <div className="max-w-screen-2xl mx-auto w-full px-4 lg:px-20">
        <div className="border border-[#D1CDCD] rounded-lg my-16  p-6 ">
          <div className="px-2 lg:px-20">
            <h1 className="text-[24px] text-center mt-4">Your questions are important to us – let’s connect!</h1>
            <hr className="my-10" />

            <div className="flex justify-between gap-y-10 items-center flex-wrap lg:flex-nowrap">
              {data &&
                data.map((item) => (

                  <div className="w-full lg:w-64 flex gap-5 items-center">
                    <div>
                      <img src={item.img} alt="" />
                    </div>
                    <div className="text-[14px] fonst-inter-serif">
                      <h1 className="font-semibold">{item.title} </h1>
                      <p>{item.para}</p>
                    </div>
                  </div>
                ))}

            </div>
          </div>

        </div>
      </div>

      <Footer />
    </main>
  );
}

export default Contact;
