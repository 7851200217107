import React from 'react';
import img1 from '../assets/blog/b1.png'
import img2 from '../assets/blog/b2.png'
import img3 from '../assets/blog/b3.png'
import img4 from '../assets/blog/b4.png'
import img5 from '../assets/blog/b5.png'
import img6 from '../assets/blog/b6.png'
import img7 from '../assets/blog/b7.png'
import img8 from '../assets/blog/b8.png'
import { NavLink } from 'react-router-dom';


function BlogCard() {
  const repeat = [
    {
      img: img1,
      title: "Transforming Businesses into AI-First Enterprises",
     path: '/blog1'
    },
    {
      img: img2,
      title: "The Power of Data for Strategic Growth",
      path: '/blog2'
    },
    {
      img: img3,
      title: "Future-Proofing Through Advanced Data Governance",
      path: '/blog3'
    },
    {
      img: img4,
      title: "Building AI Talent for Tomorrow's Challenges",
      path: '/blog4'
    },
    {
      img: img5,
      title: "The Role of Data Governance in Ensuring Data Privacy",
      path: '/blog5'
    },
    {
      img: img6,
      title: "How to Create a Data Governance Framework from Scratch",
      path: '/blog6'

    },
    {
      img: img7,
      title: "Best Practices for Effective Data Quality Management",
      path: '/blog7'

    },
    {
      img: img8,
      title: "The Role of AI in Enhancing Customer Experience",
      path: '/blog8'

    },

  ];

  return (
    <main data-aos="fade-up" className='  max-w-screen-2xl w-full mx-auto  '>
      <div data-scroll data-scroll-speed="-.5" className='mt-20 lg:mt-16' >
        <div className="text-center ">

          <h1 data-aos="fade-up" className="text-[40px]  py-20 sm:text-[64px]  leading-[39px] sm:leading-[63px]  font-inter-serif font-bold bg-gradient-to-r from-[#25537E] to-[#3DB7B7] bg-clip-text text-transparent "> Recent Blogs
          </h1>
        </div>


      </div>
      <div className='w-full flex justify-center items-center flex-wrap gap-10 md:px-14 px-4 mt-0 lg:mt-5'>
        {repeat &&
          repeat.map((item,index) => (
            <div className="font-inter-serif lg:w-80 2xl:w-96 relative" key={index}>
              {/* Heading above the image */}

              <div className="relative">
                {/* Image */}
                <img src={item.img} alt="" className="w-full h-auto" />

                <h2 className="absolute bottom-16 left-4 text-white text-[20px] font-semibold font-inter-serif mb-6">{item.title}</h2>
                {/* Button at bottom-left */}
                <NavLink to={item.path}>
                <button className="absolute bottom-10 left-4 bg-[#25537E] text-[14px] text-white py-2 px-6 rounded-full">
                  View
                </button>
                </NavLink>
              </div>
            </div>
          ))
        }

      </div>
    </main>


  );
}

export default BlogCard;
