import React from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Last from "../components/Last";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import img1 from "../assets/vector/Technology Modernization.png";
import img2 from "../assets/vector/Process Automation.png";
import img3 from "../assets/vector/Cybersecurity & Compliance.png";
import img4 from "../assets/vector/Enterprise Architecture Alignment.png";
import 'leaflet/dist/leaflet.css';



function Digital() {
  const repeat = [
    {
      img: img1,
      title: "Technology Modernization",
      description:
        "Our technology modernization services upgrade legacy systems to modern, scalable architectures, enhancing agility and supporting future growth.",
    },
    {
      img: img2,
      title: "Process Automation",
      description:
        "We automate repetitive processes with AI-powered solutions, improving efficiency, reducing costs, and freeing up resources for higher-value tasks.",
    },
    {
      img: img3,
      title: "Cybersecurity & Compliance",
      description:
        "Our cybersecurity and compliance services protect your data and systems, ensuring robust security measures and adherence to regulatory standards.",
    },
    {
      img: img4,
      title: "Enterprise Architecture Alignment",
      description:
        "We align enterprise architecture with business goals, creating an adaptable framework that supports efficient operations and strategic initiatives.",
    },
  ];

  const description =
  "Our boutique firm enhances banking technology by providing comprehensive assessments, strategic designs, and agile implementations tailored for future industry demands. we assist in aligning technological advancements with business goals to improve efficiency, enhance experiences, and ensure compliance.";

  return (
    <main>
      <Navbar />

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-14 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="max-w-screen-2xl mx-auto w-full    mb-6 text-[36px] lg:text-[48px]  text-white">
          <p className="font-inter-serif font-semibold pt-20 lg:pt-24 2xl:pt-32">Digital Transformation</p>
          
        </div>
      </div>

      <Data
      description={description} 
      repeat={repeat} />


      <Last />
      <Footer />
    </main>
  );
}

export default Digital;
