import React from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Last from "../components/Last";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import img1 from "../assets/vector/AI Elevate Online Training Courses.png";
import img2 from "../assets/vector/AI Masterclass On-Premise Focused Trainings.png";




function Talent() {
  const repeat = [
    {
      img: img1,
      title: "AI Elevate: Online Training Courses",
      description:
        "AI Elevate offers flexible, self-paced online courses designed to build foundational and advanced AI skills across all employee levels, empowering teams to leverage AI effectively.",
    },
    {
      img: img2,
      title: "AI Masterclass: On-Premise Focused Trainings",
      description:
        "AI Masterclass delivers intensive, hands-on training sessions at your location, tailored to meet specific team needs and enhance AI proficiency with real-world applications.",
    },
  ];

  const description =
  "Our talent forge services are dedicated to aligning an organization’s workforce with the skills necessary to fully harness AI and data. We believe that the success of AI initiatives heavily depends on the proficiency of the people who use these technologies.To maximize the return on AI investments, we offer customized training programs, workshops, and hands-on sessions aimed at upskilling employees and fostering a culture of continuous learning. Additionally, we help establish internal AI centers of excellence, ensuring that organizations not only adopt AI but continue to innovate and grow with it.";

  return (
    <main>
      <Navbar />

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-14 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="max-w-screen-2xl mx-auto w-full  mb-6 text-[36px] lg:text-[48px] text-white">
          <p className="pt-20 lg:pt-24 2xl:pt-32 font-inter-serif font-semibold">Talent Empowerment</p>
         
        </div>
      </div>

      <Data
      description={description} 
      repeat={repeat} />


      <Last />
      <Footer />
    </main>
  );
}

export default Talent;
