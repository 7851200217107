import React,{useEffect} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import Services from "./pages/Services";
import About from "./pages/About";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from "./pages/Contact";
import AIstrategy from "./pages/AIstrategy";
import AIimplement from "./pages/AIimplement";
import AIproduct from "./pages/AIproduct";
import Managed from "./pages/Managed";
import Digital from "./pages/Digitla";
import Talent from "./pages/Talent";
import BlockChain from "./pages/BlockChain";
import Blogs from "./pages/Blogs";
import Blog1 from "./pages/Blog1";
import Blog2 from "./pages/Blog2";
import Blog3 from "./pages/Blog3";
import Blog4 from "./pages/Blog4";


function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease",
      once: true,
    });
  }, []);
 
  return (
    <BrowserRouter>
      <>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/service" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ai-strategy" element={<AIstrategy />} />
          <Route path="/ai-implement" element={<AIimplement />} />
          <Route path="/ai-product" element={<AIproduct />} />
          <Route path="/managed-service" element={<Managed />} />
          <Route path="/digital" element={<Digital />} />
          <Route path="/talent" element={<Talent />} />
          <Route path="/blockChain" element={<BlockChain />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog1" element={<Blog1 />} />
          <Route path="/blog2" element={<Blog2 />} />
          <Route path="/blog3" element={<Blog3 />} />
          <Route path="/blog4" element={<Blog4 />} />

        </Routes>
      </>
    </BrowserRouter>
  );
}

export default App;
