import React from "react";
import landing from "../assets/media/landing.png";


function AboutUs() {
  return (
    <main id="about" className="">
      <div className="w-full py-10">



        {/* about-us--- */}
        <div className="max-w-screen-2xl w-full mx-auto px-4 mt-20">


          <div className=" w-fullps-0 lg:ps-24 px-0 flex flex-col-reverse lg:flex-row justify-between items-center flex-wrap ">
            <div data-aos="fade-left" className="lg:w-2/5  ">
              <img alt="" src={landing} />
            </div>

            <div data-aos="fade-right" className="lg:w-3/5 ">
              <div className="font-inter-serif ">
                <h1 className="text-Structure text-[#25537E] text-[32px] leading-[41px] font-semibold">Empowering You With Effective AI Implementations And Innovative Development Solutions For Every Challenge.</h1>
                <p className="text-[20px] mt-5 leading-[29px]">At Sentients, we deliver customized AI solutions designed to overcome your toughest challenges. Our expert team combines innovative technology with practical insights, empowering your business to operate smarter and achieve lasting growth.</p>
              </div>



            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AboutUs;
