import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

function Form() {

  return (
    <main id="contact" className="max-w-screen-2xl w-full mx-auto">
      <div className="md:p-16 p-4 ">
        <div data-aos="fade-down">
          <h1 className="text-[40px] font-semibold lg:text-[64px] font-inria-serif leading-[63px]">
            Let’s talk
          </h1>
        </div>

        <form data-aos="fade-up" className="my-8 flex flex-wrap lg:flex-nowrap flex-col-reverse  lg:flex-row gap-10 items-center">
          {/* Map Container */}
          <div className="w-full lg:w-1/3">
            <MapContainer center={[51.505, -0.09]} zoom={13} style={{ width: '100%' }}
              className="h-[30vh] sm:h-[40vh] md:h-[50vh] lg:h-[80vh] 2xl:h-[40vh]">
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={[51.505, -0.09]}>
                <Popup>A pretty CSS3 popup.<br /> Easily customizable.</Popup>
              </Marker>
            </MapContainer>
          </div>

          {/* Form Container */}
          <div className="w-full lg:w-2/3 bg-[#F4F4F4] px-8 py-12 rounded-2xl">
            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block font-medium text-[#0C0C0C]">First name</label>
                <div className="mt-2">
                  <input
                    required
                    type="text"
                    autoComplete="given-name"
                    className="block w-full py-1.5 text-gray-900 shadow-sm border-b-2 border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block font-medium text-[#0C0C0C]">Last name</label>
                <div className="mt-2">
                  <input
                    required
                    type="text"
                    autoComplete="given-name"
                    className="block w-full py-1.5 text-gray-900 shadow-sm border-b-2 border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="email" className="block font-medium text-[#0C0C0C]">Email</label>
                <div className="mt-2">
                  <input
                    required
                    type="email"
                    autoComplete="email"
                    className="block w-full py-1.5 text-gray-900 shadow-sm border-b-2 border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="phone" className="block font-medium text-[#0C0C0C]">Phone</label>
                <div className="mt-2">
                  <input
                    required
                    type="tel"
                    autoComplete="tel"
                    className="block w-full py-1.5 text-gray-900 shadow-sm border-b-2 border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="message" className="block font-medium text-[#0C0C0C]">Message</label>
                <div className="mt-2">
                  <textarea
                    required
                    autoComplete="message"
                    className="block w-full py-1.5 text-gray-900 shadow-sm border-b-2 border-gray-300 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm bg-transparent"
                    rows="4"
                  />
                </div>
              </div>
            </div>

            <div className="mt-8">
              <button type="submit" className="custom-button rounded-full text-[18px] px-4 py-2 cursor-pointer">
                Submit Now
              </button>
            </div>
          </div>
        </form>

      </div>
    </main>
  );
}

export default Form;
