import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import serviceImage from "../assets/banner/service.png";
import Last from "../components/Last";
import ServiceAbout from "../components/ServiceAbout";
import Field from "../components/Field";

function Service() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const paragraphs = [
    " At SentientAi, we harness the power of artificial intelligence to create innovative solutions that drive business transformation.",
  ];

  return (
    <main className="">
      <Navbar />
      <Banner
        bgImage={serviceImage}
        heading="Our Services"
        paragraphs={paragraphs} />

      <ServiceAbout />
      <Field/>

      <Last />
      <Footer />
    </main>
  );
}

export default Service;
