import React from "react";


function Banner({ bgImage, heading, paragraphs }) {
  return (
    <main
    data-aos="fade-up"
      className=" bg-cover bg-center py-40  px-10 relative top-[5.6rem]  left-0 "
      style={{
        backgroundImage: `url(${bgImage})`,
        objectFit:"cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        data-scroll
        data-scroll-speed="-.8"
        className="max-w-screen-2xl w-full mx-auto 2xl:px-20"
      >
        <div data-aos="fade-right" className="text-Structure mt-10 lg:px-10 text-white font-inter-serif">
          <div className="text-[36px] lg:text-[48px] font-bold">
            <h1>{heading}</h1>
          </div>
          {paragraphs && 
          paragraphs.map((item, index) => (
            <div
              key={index}
              className="text-[18px] lg:text-[24px] leading-[27px] tracking-tighter w-full lg:w-6/12 "
            >
              <h1>{item}</h1>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}

export default Banner;
