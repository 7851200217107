import React from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Last from "../components/Last";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import img1 from "../assets/vector/1.png";
import img2 from "../assets/vector/2.png";
import img3 from "../assets/vector/3.png";
import img4 from "../assets/vector/4.png";
import img5 from "../assets/vector/5.png";
import img6 from "../assets/vector/6.png";
import 'leaflet/dist/leaflet.css';



function AIimplement() {
  const repeat = [
    {
      img: img1,
      title: "Conversational Agents",
      description:
        "Our AI-powered conversational agents deliver seamless, human-like interactions, enhancing customer support and engagement with natural language understanding.",
    },
    {
      img: img2,
      title: "Text Analytics",
      description:
        "We transform unstructured text into actionable insights, uncovering trends and sentiment to support strategic decision-making across various domains. ",
    },
    {
      img: img3,
      title: "Prediction & Prescription",
      description:
        "Our predictive and prescriptive analytics models anticipate future outcomes and recommend actions, enabling proactive, data-driven decisions.",
    },
    {
      img: img4,
      title: "Recommendation Engine",
      description:
        "Our recommendation engines personalize user experiences by predicting preferences, boosting engagement and conversions through tailored suggestions.",
    },
   
    {
      img: img5,
      title: "Translation & Transcription",
      description:
        "We provide real-time translation and transcription services powered by AI, enabling seamless communication across languages and improving accessibility.",
    },
    {
      img: img6,
      title: "Image & Video Analytics",
      description:
        "Our image and video analytics solutions extract valuable insights from visual content, empowering applications in security, marketing, and more.",
    },
   
  ];

  const description =
  "At SentientAI, we develop innovative AI products tailored to enhance revenues, improve customer experiences, increase operational efficiency, and manage risk more effectively.  We focus on creating custom AI products that address the unique needs of individual businesses, offering a significant competitive advantage";

  return (
    <main>
      <Navbar />

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-14 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="max-w-screen-2xl mx-auto w-full  mb-6 text-[36px] lg:text-[48px] text-white">
          <p className="pt-20 lg:pt-24 2xl:pt-32 font-semibold font-inter-serif">AI Products</p>
        </div>
      </div>

      <Data
      description={description} 
      repeat={repeat} />


      <Last />
      <Footer />
    </main>
  );
}

export default AIimplement;
