import React from "react";

function BlogsPor({ blog,point, blogRepet,related }) {

  return (
    <main className="max-w-screen-2xl w-full mx-auto px-4 py-2 lg:px-14">
      {blog && 
      blog.map((item, index) =>(
        <div  key={index} className="text-justify font-inter-serif ">
          <h1 className="text-[24px] font-semibold my-8 ">{item.title}</h1>
          <p className="text-[16px] lg:text-[20px] leading-[29px] font-light">{item.para}</p>
        </div>
      ))}

{point && point.map((item, index)=>(
  <div key={index} className="text-justify font-inter-serif">
          <h1 className="text-[24px] font-semibold my-8 ">{item.heading}</h1>
        <h2 className="mt-5 text-[20px] font-light">{item.title}</h2>
        <h2 className="mt-5 text-[20px] font-semibold">{item.title1}</h2>
        <ul className="ul text-[16px] lg:text-[20px] leading-[29px] font-light">
          <li>{item.p1}</li>
          <li>{item.p2}</li>
          <li>{item.p3}</li>
        </ul>
        <h2 className="text-[20px] font-light ">{item.title2}</h2>

      </div>
      ))}

      {blogRepet && 
      blogRepet.map ((item, index) =>(
        <div key={index} className="text-justify font-inter-serif ">
             <h1 className="my-10 text-[20px] font-semibold">{item.heading}</h1>
             <h1 className="text-[20px] font-semibold">{item.title}</h1>
             <p className=" text-[16px] lg:text-[20px] leading-[29px] font-light" >{item.paragraph}</p>


        </div>
      ))}

      {related && related.map((item, index)=>(
        <div key={index} className="text-justify font-inter-serif">
        <h2 className="mt-5 text-[20px] font-semibold">{item.title}</h2>
        <ul className="ul text-[16px] lg:text-[20px] leading-[29px] font-light">
          <li>{item.p1}</li>
          <li>{item.p2}</li>
          <li>{item.p3}</li>
          
        </ul>
      </div>
      ))}

    </main>
  );
}

export default BlogsPor;
