import React, { useEffect } from "react";
import img1 from '../assets/media/mustafa_ramzan.png'
import img2 from '../assets/media/kashif_riaz.png'

function Team() {




  return (
    <main className=" py-14">
      <div data-aos="fade-up" className="px-4">
        <h1 className="font-inter-serif font-semibold  text-[36px] md:text-[48px] text-center text-black my-10" >Our Team </h1>
      </div>

      <div className="max-w-screen-2xl w-full mx-auto flex gap-12 lg:flex-nowrap justify-center flex-wrap  items-center lg:w-[58vw] 2xl:w-[30vw]  my-10">

        <div data-aos="fade-down" className=" ">
          <img src={img1} alt="" />
        </div>

        <div data-aos="fade-up" className="w-full lg:w-1/2 px-4  font-inter-serif">
          <h1 className="text-[24px] font-semibold ">Mustafa Ramzan </h1>
          <h1 className="text-[16px] font-semibold py-1">Chief Executive Officer</h1>
          <p className="text-[14px]  text-justify ">A transformational business leader with 2 decades of experience with proven track across FMCGs, Telecom and digital financial services. Seasoned in leading multi-million dollars businesses along with building businesses from scratch. Passionate about consumer insights, data analytics and AI first approach.</p>
        </div>

      </div>

      <div className="max-w-screen-2xl w-full mx-auto flex gap-12 por justify-center lg:flex-nowrap flex-wrap items-center lg:w-[58vw] 2xl:w-[30vw] my-10">

        <div data-aos="fade-up" className="w-full lg:w-1/2 px-4 font-inter-serif">
          <h1 className="text-[24px] font-semibold ">Kashif Riaz </h1>
          <h1 className="text-[16px] font-semibold py-1">Chief Operating Officer</h1>
          <p className="text-[14px]  text-justify ">A data strategist & leader with over 18 of experience lead large scale enterprise data transformation programs around the globe. Enabling customers to build data strategy to better manage, utilize and monetize data, including identifying business improvement, and aligning organizational capabilities.</p>
        </div>

        <div data-aos="fade-down" className=" ">
          <img src={img2} alt="" />
        </div>

      </div>



    </main>
  );
}

export default Team;
