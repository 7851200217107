import React from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Last from "../components/Last";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import img1 from "../assets/vector/Proactive Monitoring.png";
import img2 from "../assets/vector/System & Application Maintenance.png";
import img3 from "../assets/vector/Staff Augmentation for Data & AI.png";
import img4 from "../assets/vector/Strategic Guidance.png";
import 'leaflet/dist/leaflet.css';



function Managed() {
  const repeat = [
    {
      img: img1,
      title: "Proactive Monitoring",
      description:
        "Our proactive monitoring services of data ecosystem to detect and resolve issues before they impact performance, ensuring system reliability and uptime",
    },
    {
      img: img2,
      title: "System & Application Maintenance",
      description:
        "We provide ongoing system and application maintenance to optimize performance, security, and efficiency, supporting smooth operations.",
    },
    {
      img: img3,
      title: "Staff Augmentation for Data & AI",
      description:
        "Our staff augmentation services offer experienced Data and AI professionals to strengthen your team’s capabilities and accelerate project delivery",
    },
    {
      img: img4,
      title: "Strategic Guidance",
      description:
        "We deliver expert strategic guidance to help you navigate AI and data transformation, aligning solutions with your long-term business goals",
    },
  ];

  const description =
  "Our Strategic Advisory services are designed to guide organizations in becoming AI-first enterprises by crafting tailored AI and data strategies that align with their specific business goals. In today’s digital landscape, having a well-defined AI strategy is crucial for maintaining a competitive edge, driving innovation, and achieving operational efficiencies.";

  return (
    <main>
      <Navbar />

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-14 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="max-w-screen-2xl mx-auto w-full  font-inria-sans font-semibold mb-6 text-[36px] lg:text-[48px] tracking-[7px] text-white">
          <p className="font-semibold pt-20 lg:pt-24 2xl:pt-32 font-inter-serif">Managed Services</p>
          
        </div>
      </div>

      <Data
      description={description} 
      repeat={repeat} />


      <Last />
      <Footer />
    </main>
  );
}

export default Managed;
