import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import LandingPage from "../components/LandingPage";
import AboutUs from "../components/AboutUs";
import WhyWe from "../components/WhyWe";
import Cards from "../components/Cards";
import Field from "../components/Field";
import Experience from "../components/Experience";
import Review from "../components/Review";
import Form from "../components/Form";
import Footer from "../components/Footer";
import Last from "../components/Last";

function App() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  return (
    <>
      <main className=" ">
        <div className=" z-16">
          <Navbar />
        </div>
        <LandingPage />
        <AboutUs />
        {/* <WhyWe /> */}
        {/* <Cards /> */}
      
        <Field />
        {/* <Experience /> */}
        {/* <Review /> */}
        {/* <Form /> */}
        <Last />
        <Footer />
      </main>
    </>
  );
}

export default App;
