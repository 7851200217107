import { useState, useEffect } from "react";
import img1 from '../assets/banner/MAINBANNER.png';
import img2 from '../assets/banner/img2.png';
import img3 from '../assets/banner/img3.png';
import img4 from '../assets/banner/IMG4.png';

function LandingPage() {
  const slides = [
    {
      image: img1, 
      text: "Transforming Businesses into AI-First Enterprises",
      para: "We empower organizations to adopt AI-first strategies, enabling smarter decisions, enhanced agility, and sustained competitive advantage."
    },
    {
      image: img2, 
      text: "Unlocking the Power of Data for Strategic Growth",
      para: "Our data solutions help you harness actionable insights, turning raw data into valuable intelligence that drives business success."
    },
    {
      image: img3,
      text: "Future-Proofing Through Advanced Data Governance",
      para: "We build robust governance frameworks that ensure data quality, compliance, and security, enabling trustworthy data-driven growth."
    },
    {
      image: img4, 
      text: "Building AI Talent for Tomorrow’s Challenges",
      para: "Our tailored training and advisory services equip your team with the skills needed to embrace and excel in an AI-driven world."
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [bgImage, setBgImage] = useState(slides[0].image);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      setBgImage(slides[(currentSlide + 1) % slides.length].image);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentSlide]);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
    setBgImage(slides[index].image);
  };

  return (
    <main
    className="relative py-20 2xl:py-48 bg-cover bg-center transition-all duration-1000 ease-in-out"
    style={{
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',       
      backgroundPosition: 'center', 
     
    }}
  >
      {/* Container */}
      <div className="absolute inset-0 bg-black bg-opacity-40 z-0"></div> 
      <div className="relative z-10 max-w-screen-2xl px-0 lg:px-32 w-full mx-auto mt-10">
        {/* Text Content */}
        <div className="text-Structure mt-40 lg:mt-48 md:px-14 px-3 ">
          <div className="mb-6 text-sm lg:text-[24px] tracking-[7px] text-white">
            <p className="font-normal">INNOVATION AT EVERY TURN</p>
          </div>
          <div>
            <h1 className="text-[36px] lg:text-[75px] leading-[50px] lg:leading-[90px] text-white font-semibold font-inria-serif">
              {slides[currentSlide].text}
            </h1>
            <p className="text-[16px] lg:text-[24px] leading-[30px] text-white py-3 font-inria-sans">
              {slides[currentSlide].para}
            </p>
          </div>
        </div>

        {/* Dots/Indicators */}
        <div  className="absolute  bottom-[-45px] 2xl:bottom-[-95px] left-1/2 transform -translate-x-1/2 flex space-x-4 ">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`w-3 h-3 rounded-full bg-white cursor-pointer transition-all duration-300 ${
                currentSlide === index ? "bg-opacity-100 scale-125" : "bg-opacity-50"
              }`}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div>

        {/* Scroll Down Button */}

        {/* <div className="mb-10 md:block hidden lg:flex gap-10 items-center px-14 lg:mt-16">
          <a href="#about" className="flex gap-5 items-center cursor-pointer text-[24px] pt-10">
            <div className="rounded-full text-lg uppercase lg:-mt-8 text-white">
              <FaArrowDown />
            </div>
            <div className="hidden lg:block text-[#fff]">
              Scroll <br /> to explore
            </div>
          </a>
        </div> */}

      </div>
    </main>
  );
}

export default LandingPage;
