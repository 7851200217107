import React from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import img1 from "../assets/vector/Data Strategy.png";
import img2 from "../assets/vector/Vector.png";
import img3 from "../assets/vector/Data Architecture Roadmap.png";
import img4 from "../assets/vector/Talent & Data Culture Advisory.png";
import Last from "../components/Last";



function AIstrategy() {
  const repeat = [
    {
      img: img1,
      title: "Data Strategy",
      description:
        "Our Data Strategy services help organizations unlock the full potential of their data assets. We design tailored strategies that align with your business objectives, ensuring data becomes a central driver of growth and innovation.",
    },
    {
      img: img2,
      title: "Data Governance Framework",
      description:
        "We create comprehensive Data Governance frameworks to ensure data quality, compliance, and security. Our approach establishes clear policies and controls, empowering your organization to make data-driven decisions with confidence.",
    },
    {
      img: img3,
      title: "Data Architecture Roadmap",
      description:
        "Our Data Architecture Roadmap provides a scalable blueprint for managing and integrating data across your organization. We design robust, future-proof architectures that support data accessibility, reliability, and efficiency.",
    },
    {
      img: img4,
      title: "Talent & Data Culture Advisory",
      description:
        "Our Talent & Data Culture Advisory services foster an AI-ready workforce and data-driven mindset. Through targeted training and advisory, we empower your team to fully leverage data for strategic advantage.",
    },
  ];

  const description =
  "Our Strategic Advisory services are designed to guide organizations in becoming AI-first enterprises by crafting tailored AI and data strategies that align with their specific business goals. In today’s digital landscape, having a well-defined AI strategy is crucial for maintaining a competitive edge, driving innovation, and achieving operational efficiencies.";

  return (
    <main>
      <Navbar />

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-14 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="max-w-screen-2xl mx-auto w-full  mb-6 text-[36px] lg:text-[48px]  text-white">
          <p className="pt-20 lg:pt-24 2xl:pt-32 font-semibold font-inter-serif">Data & AI Strategy</p>
          
        </div>
      </div>

      <Data
      description={description} 
      repeat={repeat} />


      <Last />
      <Footer />
    </main>
  );
}

export default AIstrategy;
