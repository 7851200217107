import React from 'react';

function Field() {
  const repeat = [
    {
      no: "01",
      title: "Data & AI Strategy",
      description: "Build strategies for data governance and organizational insights."
    },
    {
      no: "02",
      title: "Data & AI Implementation Services ",
      description: "Build strategies for data governance and organizational insights."
    },
    {
      no: "03",
      title: "AI Products",
      description: "Develop AI tools for smarter business and insights."
    },
    {
      no: "04",
      title: "Managed Services",
      description: "Deliver monitoring, maintenance, and support for opera."
    },
    {
      no: "05",
      title: "Digital Transformation",
      description: "Drive process automation and cybersecurity for growth."
    },
    {
      no: "06",
      title: "Talent Empowerment",
      description: "Upskill teams with advanced AI training programs."
    },
    {
      no: "06",
      title: "Blockchain",
      description: "Blockchain enhances security, transparency, and efficiency."
    },

  ];

  return (
    <main data-aos="fade-up" className='rounded-t-3xl  py-2 lg:py-10 max-w-screen-2xl w-full mx-auto  '>
      <div
        data-scroll
        data-scroll-speed="-.5"
        className="lg:flex justify-center  md:p-14 p-4  "
      >
        <div className="text-center ">
          
          <h1 data-aos="fade-up" className="font-semibold  text-[40px] sm:text-[64px]  leading-[39px] sm:leading-[63px] mt-10 font-inter-serif">
            Services 
            <span className="font-semibold bg-gradient-to-r from-[#3DB7B7] to-[#25537E] bg-clip-text text-transparent "> We Offer</span>{" "}
          </h1>
        </div>

       
      </div>
      <div className='w-full flex justify-center items-center flex-wrap gap-10 md:px-14 px-4 mt-10'>
        {repeat &&
          repeat.map((item) => (
            <div className=' font-inter-serif w-96 h-64 p-8 border rounded-lg text-center border-[#D6D6D6]' key={item.no}>
              <div>
                {/* <p className='text-[#7D7D7D]'>{item.no}</p> */}
                <h1 className='text-[24px] font-semibold'>{item.title}</h1>
              </div>

              <div className='mt-4'>
                <p className='lg:text-[20px] text-sm leading-[29px] font-light'>{item.description}</p>
              </div>
            </div>
          ))}
      </div>
    </main>
    

  );
}

export default Field;
