import React from "react";
import Navbar from "../components/Navbar";
import Data from "../components/Data";
import Form from "../components/Form";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import img1 from "../assets/vector/ac.png";
import img2 from "../assets/vector/Data Governance.png";
import img3 from "../assets/vector/Data Migration.png";
import img4 from "../assets/vector/a1.png";
import img5 from "../assets/vector/Data Engineering.png";
import img6 from "../assets/vector/Reporting & Data Visualization.png";
import img7 from "../assets/vector/Business Intelligence.png";
import img8 from "../assets/vector/FActory.png";



function AIimplement() {
  const repeat = [
    {
      img: img1,
      title: "Business Use Cases",
      description:
        "We identify and develop AI-driven business use cases that create tangible value, aligning data solutions with your unique operational goals.",
    },
    {
      img: img2,
      title: "Data Governance",
      description:
        "Our Data Governance services establish rigorous policies and controls, ensuring data quality, compliance, and accountability across your organization. ",
    },
    {
      img: img3,
      title: "Data Migration",
      description:
        "We streamline data migration with secure, efficient processes that minimize disruption, enabling seamless transitions to modern data platforms.",
    },
    {
      img: img4,
      title: "Talent & Data Culture Advisory",
      description:
        "Our Data Science solutions unlock deep insights and predictive capabilities, empowering data-driven decisions and operational improvements.",
    },
    {
      img: img5,
      title: "Data Engineering",
      description:
        "We build and optimize data pipelines that enhance data flow, reliability, and scalability, supporting advanced analytics and AI initiatives.",
    },
    {
      img: img6,
      title: "Reporting & Data Visualization",
      description:
        "We design intuitive reporting and data visualization that transform complex data into clear, actionable insights for better decision-making.",
    },
    {
      img: img7,
      title: "Business Intelligence",
      description:
        "Our Data Science solutions unlock deep insights and predictive capabilities, empowering data-driven decisions and operational improvements.",
    },
    {
      img: img8,
      title: "Data Factory Setup",
      description:
        "We set up robust data ecosystem to automate data processing, ensuring efficient, scalable data stores and tools that support your analytics and AI needs.",
    },
  ];

  const description =
  "We focus on delivering customized projects that transform raw data into actionable insights and predictive models. Our expert team then designs and implements bespoke solutions, utilizing cutting-edge tools and models to optimize operations and uncover new growth opportunities";

  return (
    <main>
      <Navbar />

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-14 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="max-w-screen-2xl mx-auto w-full mb-6 text-[36px] lg:text-[48px]  text-white">
          <p className="pt-20 lg:pt-24 2xl:pt-32 font-semibold font-inter-serif">Data & AI Implementation</p>
        </div>
      </div>

      <Data
      description={description} 
      repeat={repeat} />


      <Form />
      <Footer />
    </main>
  );
}

export default AIimplement;
