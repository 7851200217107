import React from "react";
import landing from "../assets/banner/23 1.png";


function ServiceAbout() {
  return (
    <main id="about" className="my-10">
      <div className="w-full py-20">



        {/* about-us--- */}
        <div className="max-w-screen-2xl w-full mx-auto px-4 mt-20">


          <div className=" w-fullps-0 lg:ps-24 px-0 flex flex-col-reverse lg:flex-row justify-between items-center flex-wrap ">
            <div data-aos="fade-left" className="lg:w-2/5  ">
              <img alt="" src={landing} />
            </div>

            <div data-aos="fade-right" className="lg:w-3/5 ">
              <div className="font-inter-serif ">
                <p className="  leading-[29px]">At Sentients AI, we’re your strategic partner in innovation. Our skilled development team is dedicated to delivering tailored solutions that meet your unique needs and drive your business success.  </p>
                <p className=" leading-[29px]">We specialize in implementing new capabilities, modernizing legacy systems, and optimizing the effectiveness and efficiency of your existing operations.</p>
              </div>



            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ServiceAbout;
