import React from "react";
import logo from "../assets/logo/logo.png";
import insta from "../assets/vector/insta.png";
import fb from "../assets/vector/fb.png";
import linked from "../assets/vector/linkind.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="max-w-screen-2xl w-full mx-auto">
      <div className="px-14">
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 justify-between items-center py-5 text-center md:text-left">
  <div>
    <img src={logo} alt="" />
  </div>
  <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 items-center md:gap-x-10  text-[#979797] text-[14px] cursor-pointer">
    <Link to="/services">Service</Link>
    <Link to="/about">About us</Link>
    <Link to="/">Blogs</Link>
    <Link to="/">Privacy Policy</Link>
    <Link to="/">Terms of Service</Link>
  </div>
</div>

        <hr className="bg-black h-1 my-5" />
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 justify-between items-center py-5 text-center md:text-left">
  <div>
    <h1 className="text-[#131126] text-[14px]">
      All rights reserved ® SentientAI.com | Terms and conditions apply!
    </h1>
  </div>
  <div className="flex items-center gap-10 text-[#979797] text-[14px] cursor-pointer justify-center md:justify-start">
    <img src={fb} alt="Facebook" />
    <img src={insta} alt="insta" />
    <img src={linked} alt="Payment" />
  </div>
</div>

      </div>
    </footer>
  );
}

export default Footer;
