import React from "react";
import Navbar from "../components/Navbar";
import Last from "../components/Last";
import Footer from "../components/Footer";
import contactImage from "../assets/banner/strabanne.png";
import landing from '../assets/media/k.png'
import line from '../assets/vector/LINE.png'


function BlockChain() {

  return (
    <main>
      <Navbar />

      <div
        data-aos="fade-down"
        className="h-[50vh] 2xl:h-[50vh] lg:h-[60vh] text-Structure bg-cover bg-center transition-all duration-1000 ease-in-out flex items-center px-14 "
        style={{
          backgroundImage: ` url(${contactImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="max-w-screen-2xl mx-auto w-full    mb-6 text-[36px] lg:text-[48px]  text-white">
          <p className="font-inter-serif font-semibold pt-20 lg:pt-24 2xl:pt-32">Blockchain</p>

        </div>
      </div>

      <div className="w-full py-10">
        <div className="max-w-screen-2xl w-full mx-auto px-4 mt-20">
          <div className=" w-full ps-0 lg:ps-24 px-0 flex gap-y-5 flex-col-reverse lg:flex-row justify-between items-center flex-wrap ">
            <div data-aos="fade-left" className="lg:w-2/5  ">
              <img alt="" src={landing} />
            </div>

            <div data-aos="fade-right" className="lg:w-3/5 px-4 lg:px-16">
              <div className="font-inter-serif ">
                <h1 className="text-Structure  text-[36px] leading-[41px] font-semibold">Your <span className="text-[#25537E]">Business </span> Ready For The Blockchain</h1>
                <p className="text-[14px] text-[#9E9E9E] mt-5 leading-[21px]">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quam accusantium delectus mollitia molestias quasi nobis.</p>

                <div className=" mt-5 ">

                  <div className="flex items-center justify-between  border border-[#9F9F9F66] rounded-full bg-transparent">
                    <div></div>
                    <div className="bg-[#25537E] text-white rounded-full inline-block px-6 py-3">
                      <h1 className="font-inter-serif">Join Now</h1>
                    </div>
                  </div>

                </div>
              </div>



            </div>
          </div>
        </div>
      </div>

      <div className="py-10 max-w-screen-2xl mx-auto w-full lg:relative ">
        <h1 className="text-center text-[36px] font-inter-serif my-5"> RoadMap</h1>

        <div className="flex justify-between mt-10">
          <div className="flex flex-wrap lg:flex-nowrap  lg:gap-10 px-6 lg:px-0">
            <div className="w-full">
              <h1 className="text-[32px] text-start lg:text-end leading-[38px] lg:leading-[48px]">Reduce transaction <br /> costs</h1>
            </div>
            <div className="">
              <img src={line} alt="Line" className="w-full hidden lg:block" />
            </div>


            <div>
              <p className="leading-[24px] text-[#9E9E9E] w-full  2xl:max-w-[1000px] lg:max-w-[800px]">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit esse quia excepturi mollitia! Officia, cupiditate?
              </p>
              <p className="leading-[24px] text-[#9E9E9E] w-full  2xl:max-w-[1000px] lg:max-w-[800px]">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit esse quia excepturi mollitia! Officia, cupiditate? Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit esse quia excepturi mollitia! Officia

              </p>
            </div>
          </div>
        </div>

        <div className="lg:absolute top-[20rem] 2xl:mx-[6.5rem] mt-10 lg:mt-0 ">
          <div className=" flex flex-wrap lg:flex-nowrap  lg:gap-20 px-6 lg:px-0">
            <div className="w-full">
              <h1 className="text-[32px] text-start lg:text-end leading-[38px] lg:leading-[48px]">Bolster security and <br /> transparency</h1>
            </div>


            <div>
              <p className="leading-[24px] text-[#9E9E9E] 2xl:max-w-[1000px] lg:max-w-[800px]">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit esse quia excepturi mollitia! Officia, cupiditate?
              </p>
              <p className="leading-[24px] text-[#9E9E9E] 2xl:max-w-[1000px] lg:max-w-[800px]">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit esse quia excepturi mollitia! Officia, cupiditate?
              </p>
            </div>
          </div>
        </div>

        <div className="lg:absolute top-[29rem] 2xl:mx-[6.5rem] mt-10 lg:mt-0  ">
          <div className="flex flex-wrap lg:flex-nowrap  lg:gap-20 px-6 lg:px-0 ">
            <div className="w-full">
              <h1 className="text-[32px]  text-start lg:text-end leading-[38px] lg:leading-[48px]">Innovative payment <br /> options</h1>
            </div>


            <div>
              <p className="leading-[24px] text-[#9E9E9E] max-w-[900px] w-full mx-auto">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit esse quia excepturi mollitia! Officia, cupiditate?
              </p>

            </div>
          </div>
        </div>
        <div className="lg:absolute top-[36rem] mx-6 2xl:mx-[8rem] mt-10 lg:mt-0 ">
          <div className="flex flex-wrap lg:flex-nowrap  lg:gap-20 ">
            <div className="w-full">
              <h1 className="text-[32px] leading-[38px] lg:leading-[48px] text-start lg:text-end">Boost operational <br /> efficiency</h1>
            </div>


            <div>
              <p className="leading-[24px] text-[#9E9E9E] max-w-[900px] w-full mx-auto">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit esse quia excepturi mollitia! Officia, cupiditate?
              </p>
              <p className="leading-[24px] text-[#9E9E9E] max-w-[900px] w-full mx-auto">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit esse quia excepturi mollitia! Officia, cupiditate?
              </p>
            </div>
          </div>
        </div>


      </div>



      <Last />
      <Footer />
    </main>
  );
}

export default BlockChain;
